<template>
  <div class="layout-container">
    <header class="layout-header">
      <img v-if="isEzeeship" src="@/assets/ezeeship-logo.png" alt="">
      <img v-if="isShipout" src="@/assets/shipout-logo.png" alt="">
      <img v-if="isShipA2z" src="@/assets/shipa2z-logo.png" alt="">
    </header>
    <div class="layout--breadcrumb">
      <BreadCrumbs :items="breadcrumbs" />
    </div>
    <div class="split-block" v-if="isShowSplitBlock" />
    <main class="layout-main">
      <div class="router-view-wrap">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import { BUSINESSES } from '@/config'

export default {
  data () {
    return {
    }
  },
  created () {
    let map = {
      [BUSINESSES.EZEESHIP.VALUE]: '/ezeeship-favicon.ico',
      [BUSINESSES.SHIPOUT.VALUE]: '/shipout-favicon.ico',
      [BUSINESSES.WMS.VALUE]: '/shipout-favicon.ico',
      [BUSINESSES.SHIPA2Z.VALUE]: '/shipa2z-favicon.ico'
    }
    this.changeFavicon(map[this.source])
  },
  computed: {
    isEzeeship () {
      return this.source === BUSINESSES.EZEESHIP.VALUE
    },
    isShipout () {
      let bs = [BUSINESSES.WMS.VALUE, BUSINESSES.SHIPOUT.VALUE]
      return bs.includes(this.source)
    },
    isShipA2z () {
      return this.source === BUSINESSES.SHIPA2Z.VALUE
    },
    source () {
      return this.$route.params.source
    },
    isShowSplitBlock () {
      return this.$route.name !== 'ticket-list'
    },
    breadcrumbs () {
      const source = this.$route.params.source
      const curKey = Object.keys(BUSINESSES).find(key => BUSINESSES[key].VALUE === source)
      const { VALUE } = BUSINESSES[curKey]
      const map = {
        'ticket-list': [
          { label: 'Support Case', path: null }
        ],
        'ticket-create': [
          { label: 'Support Case', path: `/${VALUE}/list` },
          { label: 'Create Support Case', path: null }
        ],
        'ticket-detail': [
          { label: 'Support Case', path: `/${VALUE}/list` },
          { label: this.$store.state.detailCaseNo, path: null }
        ]
      }
      return map[this.$route.name]
    }
  },
  methods: {
    changeFavicon (link) {
      let $favicon = document.querySelector('link[rel="icon"]')
      if ($favicon !== null) {
        $favicon.href = link
      } else {
        $favicon = document.createElement('link')
        $favicon.rel = 'icon'
        $favicon.href = link
        document.head.appendChild($favicon)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.layout-container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  .layout-header{
    height: 50px;
    border: 1px solid #F8F8F8;
    padding: 0 $page-gap;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    img{
      padding: 10px 0;
      height: 100%;
    }
  }
  .layout--breadcrumb{
    padding: 16px;
  }
  .split-block{
    height: 8px;
    background: #F9FAFC;
  }
  .layout-main{
    flex: 1;
    height: 0;
    .router-view-wrap{
      height: 100%;
    }
  }
}
</style>
